var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var vY,zoa,Aoa,Boa,Coa,CY,Doa,Eoa,DY,Foa,FY,Goa,Hoa,Ioa,KY,LY,Joa,Koa,TY,Loa,Moa,Noa,YY,Ooa;vY=function(){};$CLJS.wY=function(a){return null!=a?$CLJS.yc===a.ni?!0:a.Wc?!1:$CLJS.Ya(vY,a):$CLJS.Ya(vY,a)};zoa=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.xY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Aoa=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Boa=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.yY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.zY=new $CLJS.M(null,"database-id","database-id",1883826326);Coa=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.AY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.BY=new $CLJS.M(null,"search","search",1564939822);CY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Doa=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Eoa=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
DY=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Foa=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.EY=new $CLJS.M(null,"dataset","dataset",1159262238);FY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Goa=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);
$CLJS.GY=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.HY=new $CLJS.M(null,"definition","definition",-1198729982);Hoa=new $CLJS.M(null,"state","state",-1988618099);$CLJS.IY=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Ioa=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.JY=new $CLJS.M("source","joins","source/joins",1225821486);
KY=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);LY=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.MY=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.NY=new $CLJS.M(null,"selected?","selected?",-742502788);Joa=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);
Koa=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.OY=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.PY=new $CLJS.M("source","card","source/card",-139977973);$CLJS.QY=new $CLJS.M(null,"active","active",1895962068);$CLJS.RY=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.SY=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);
TY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Loa=new $CLJS.M(null,"details","details",1956795411);$CLJS.UY=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.VY=new $CLJS.M(null,"has-field-values","has-field-values",626648377);Moa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.WY=new $CLJS.M("source","native","source/native",-1444604147);
Noa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.XY=new $CLJS.M(null,"auto-list","auto-list",314197731);YY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.ZY=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Ooa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
$CLJS.$Y=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.aZ=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.Y(KY,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Hj,$CLJS.PY,$CLJS.WY,$CLJS.yY,$CLJS.AY,$CLJS.UY,$CLJS.kK,$CLJS.cK,$CLJS.JY,$CLJS.GY,$CLJS.IY],null));$CLJS.Y(DY,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Hj],null),$CLJS.Cz($CLJS.Dd,new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.XY,null,$CLJS.BY,null,$CLJS.fj,null,$CLJS.cz,null],null),null))));
$CLJS.Y(TY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.jK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.UJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VJ,$CLJS.CD],null)],null));
$CLJS.Y(CY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.eK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.UJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.ur],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.ur],null)],null)],null));
$CLJS.Y($CLJS.TJ,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.MJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.DD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.CD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.er,$CLJS.yj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pi,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.DD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$J,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.CD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.CD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$Y,
new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.tD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.tD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.WJ],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.tD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,2,[$CLJS.ti,1,$CLJS.Ej,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.VY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fK,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CY],null)],null)],
null)],null));$CLJS.Y(YY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OY,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[Goa,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[Foa,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.DD],null)],null)],null)],null)],null)],null));
$CLJS.Y(FY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QY,$CLJS.tr],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hoa,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OY,$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LJ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,
$CLJS.tD],null)],null)],null));
$CLJS.Y(Moa,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.iK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.WJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zY,$CLJS.XJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Dj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SY,
new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.Dj],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EY,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aK,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.PJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Boa,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,FY],null)],null)],null)],null));
$CLJS.Y(Joa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.OJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.lD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Dj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.tD],null)],null)],null));
$CLJS.Y(Koa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.QJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.jD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Dj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.tD],null)],null)],null));
$CLJS.Y(Noa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.SJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.tD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.h(null,
1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.tD],null)],null)],null));
$CLJS.Y(Aoa,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.gK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.XJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[Eoa,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Dj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Loa,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Dj],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.aZ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.Ii],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dz,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Li,$CLJS.Ii],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ioa,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Dj],null)],null)],null));
$CLJS.Y(LY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"Valid MetadataProvider"],null),new $CLJS.Cc(function(){return $CLJS.wY},Coa,$CLJS.zg([$CLJS.Hi,$CLJS.U,$CLJS.Lj,$CLJS.fi,$CLJS.Ci,$CLJS.Vi,$CLJS.Wj,$CLJS.ei,$CLJS.Fj,$CLJS.Kj],[zoa,Ooa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Jp],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.wY)?$CLJS.wY.H:null]))],null));
$CLJS.Y(Doa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LY],null)],null)],null)],null));