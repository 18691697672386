var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var EK,GK,IK,LK,OK;$CLJS.zK=function(a){return $CLJS.Hh($CLJS.q($CLJS.gB),a,$CLJS.rF)};$CLJS.AK=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.BK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.CK=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.DK=new $CLJS.M(null,"object-id","object-id",-754527291);EK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);
$CLJS.FK=new $CLJS.M(null,"operators","operators",-2064102509);GK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.HK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);IK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.JK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.KK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
LK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.MK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.NK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);OK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.PK=new $CLJS.M(null,"null","null",-180137709);
$CLJS.QK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(GK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.yD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tF,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.eD],null)],null)],null));$CLJS.Y(OK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,GK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.DD],null)],null)],null));$CLJS.Y(IK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.nF],null),OK,$CLJS.tD],null));
$CLJS.Y(LK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.nF],null),GK,$CLJS.CD],null));
$CLJS.MF.g($CLJS.nF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.nF],null),GK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.CD,$CLJS.tD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.yi,function(a){return $CLJS.rd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.XA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,LK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,IK],null)],null)],null));$CLJS.tE($CLJS.nF,$CLJS.rF);$CLJS.OD.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.pi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.bE});$CLJS.AE($CLJS.qA,$CLJS.H([$CLJS.tD]));$CLJS.OD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.pi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.bE});
$CLJS.tE($CLJS.qA,$CLJS.rF);$CLJS.Y(EK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.yD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BK,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tD],null)],null)],null));
$CLJS.MF.g($CLJS.cF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pj,$CLJS.cF],null),EK,$CLJS.yj],null));$CLJS.OD.m(null,$CLJS.cF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.pi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.bE});$CLJS.tE($CLJS.cF,$CLJS.rF);$CLJS.AE($CLJS.LE,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lD],null)],null)]));$CLJS.tE($CLJS.LE,$CLJS.rF);
$CLJS.AE($CLJS.iF,$CLJS.H([$CLJS.Zr,$CLJS.bE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jD],null)],null)]));$CLJS.tE($CLJS.iF,$CLJS.rF);
$CLJS.Y($CLJS.rF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.QD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Dd,$CLJS.Ik.g($CLJS.q($CLJS.gB),$CLJS.rF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.zK(a)}],null)],null));